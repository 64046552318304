import { MortgageStatus } from 'shared/models/Mortgage';
import { Role } from '../shared/models/User';

// TODO: Replace this by MortgageRoutes class and similar
const routeStrings = {
  login: 'login',
  changePassword: 'change-password',
  support: 'https://support.3homes.com',
  originator: {
    mortgage: {
      root: 'mortgage/*',
      base: 'mortgage',
      draft: 'draft',
      open: 'open',
      finalize: 'finalize',
      close: 'sold'
    },
    bidTape: 'bid-tape',
    admin: 'user',
    qualityControl: {
      root: 'quality-control/*',
      base: 'quality-control',
      draft: MortgageStatus.Draft,
      open: MortgageStatus.Open,
      pending: MortgageStatus.Pending,
      close: MortgageStatus.Sold,
      documentReview: 'document-review'
    },
    quality: {
      root: 'quality/*',
      base: 'quality',
      documentCategorization: 'doc-cat',
      closeChecklist: 'close-checklist'
    }
  },
  investor: {
    mortgage: { root: 'mortgage/*', base: 'mortgage', open: 'open', close: 'purchased' },
    bidTape: 'bid-tape',
    admin: 'user'
  }
};

class MortgageRoutes {
  static base = 'mortgage';

  static get routePath() {
    return `/${this.base}/:mortgageUrlSlug/:bidTapeId`;
  }

  static get draft() {
    return {
      path: 'draft',
      get route() {
        return `${MortgageRoutes.base}/${this.path}`;
      },
      allowed: [Role.Trader, Role.TradeViewer]
    };
  }

  static get open() {
    return {
      path: 'open',
      get route() {
        return `${MortgageRoutes.base}/${this.path}`;
      },
      allowed: [Role.Trader, Role.TradeViewer]
    };
  }

  static get finalize() {
    return {
      path: 'finalize',
      get route() {
        return `${MortgageRoutes.base}/${this.path}`;
      },
      allowed: [Role.Trader, Role.TradeViewer]
    };
  }

  static get close() {
    return {
      path: 'sold',
      get route(): string {
        return `${MortgageRoutes.base}/${this.path}`;
      },
      allowed: [Role.Trader, Role.TradeViewer, Role.FullDataAction, Role.FullDataViewer]
    };
  }
}

export { MortgageRoutes };
export default routeStrings;
