import { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import routeStrings from 'Routing/routeStrings';
import { SuspenseWithLoader } from 'Routing/SuspenseWrapper';
import { Role } from 'shared/models/User';
import { isQualityAllowed, permissionChecker } from 'shared/utils/permissionChecker';
import MortgageStatusValidator from 'Sections/Originator/Pages/QualityControl/MortgageStatusValidator';
import DocumentIdValidator from 'Sections/Originator/Pages/QualityControl/DocumentReview/components/DocumentIdValidator';
import { ChecklistReviewFilter } from 'Sections/Originator/Pages/Quality/CloseChecklist/components/ChecklistTablePanel/ChecklistTableHeader';
import { withSentryReactRouterV6Routing } from '@sentry/react';

const Mortgage = lazy(() => import('Sections/Originator/Pages/Mortgage/Mortgage'));
const BidTape = lazy(() => import('Sections/Originator/Pages/BidTape/BidTape'));
const Admin = lazy(() => import('Sections/Originator/Pages/Admin/Admin'));
const DocumentReview = lazy(() => import('Sections/Originator/Pages/QualityControl/DocumentReview'));
const Categorized = lazy(
  () => import('Sections/Originator/Pages/QualityControl/DocumentReview/components/Categorized')
);

const DraftTable = lazy(() => import('Sections/Originator/Pages/Mortgage/Components/DraftTable'));
const OpenTable = lazy(() => import('Sections/Originator/Pages/Mortgage/Components/OpenTable'));
const FinalizeTable = lazy(() => import('Sections/Originator/Pages/Mortgage/Components/FinalizeTable'));
const CloseTable = lazy(() => import('Sections/Originator/Pages/Mortgage/Components/ClosedTable'));

const Quality = lazy(() => import('Sections/Originator/Pages/Quality'));
// const DocumentCategorization = lazy(() => import('Sections/Originator/Pages/Quality/DocumentCategorization'));
const CloseChecklist = lazy(() => import('Sections/Originator/Pages/Quality/CloseChecklist'));

const { mortgage, bidTape, admin, quality } = routeStrings.originator;

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

export const OriginatorRoutes = ({ role }: { role: Role[] }) => (
  <SuspenseWithLoader>
    <SentryRoutes>
      {permissionChecker(role, [], [Role.Trader, Role.TradeViewer, Role.FullDataAction, Role.FullDataViewer]) && (
        <Route path={mortgage.root}>
          {permissionChecker(role, [], [Role.Trader, Role.TradeViewer]) && (
            <>
              {/* For some reason /:bidTapeId? does not work */}
              <Route path={mortgage.draft}>
                <Route
                  index
                  element={
                    <SuspenseWithLoader viewportHeight={false} viewportWidth={false}>
                      <Mortgage key="draft-table">
                        <DraftTable />
                      </Mortgage>
                    </SuspenseWithLoader>
                  }
                />
                <Route
                  path=":bidTapeId"
                  element={
                    <SuspenseWithLoader viewportHeight={false} viewportWidth={false}>
                      <Mortgage key="draft-table">
                        <DraftTable />
                      </Mortgage>
                    </SuspenseWithLoader>
                  }
                />
              </Route>

              <Route path={`${mortgage.open}`}>
                <Route
                  index
                  element={
                    <SuspenseWithLoader viewportHeight={false} viewportWidth={false}>
                      <Mortgage key="open-table">
                        <OpenTable />
                      </Mortgage>
                    </SuspenseWithLoader>
                  }
                />
                <Route
                  path=":bidTapeId"
                  element={
                    <SuspenseWithLoader viewportHeight={false} viewportWidth={false}>
                      <Mortgage key="open-table">
                        <OpenTable />
                      </Mortgage>
                    </SuspenseWithLoader>
                  }
                />
              </Route>
              <Route path={`${mortgage.finalize}`}>
                <Route
                  index
                  element={
                    <SuspenseWithLoader viewportHeight={false} viewportWidth={false}>
                      <Mortgage key="finalize-table">
                        <FinalizeTable />
                      </Mortgage>
                    </SuspenseWithLoader>
                  }
                />
                <Route
                  path=":bidTapeId"
                  element={
                    <SuspenseWithLoader viewportHeight={false} viewportWidth={false}>
                      <Mortgage key="finalize-table">
                        <FinalizeTable />
                      </Mortgage>
                    </SuspenseWithLoader>
                  }
                />
              </Route>
            </>
          )}
          <Route
            path={`${mortgage.close}`}
            element={
              <SuspenseWithLoader viewportWidth={false} viewportHeight={false}>
                <Mortgage key="close-table">
                  <CloseTable />
                </Mortgage>
              </SuspenseWithLoader>
            }
          />

          {permissionChecker(role, [], [Role.Trader, Role.TradeViewer]) ? (
            <Route path="*" element={<Navigate to={mortgage.draft} />} />
          ) : (
            <Route path="*" element={<Navigate to={mortgage.close} />} />
          )}
        </Route>
      )}
      {permissionChecker(role, [], [Role.Trader, Role.TradeViewer]) && <Route path={bidTape} element={<BidTape />} />}
      {permissionChecker(role, [], [Role.UserManagement]) && <Route path={admin} element={<Admin />} />}

      {isQualityAllowed(role) && (
        <Route path={quality.root}>
          <Route
            index
            element={
              <SuspenseWithLoader viewportWidth={false}>
                <Quality />
              </SuspenseWithLoader>
            }
          />
          <Route
            path=":mortgageId"
            element={
              <SuspenseWithLoader viewportWidth={false}>
                <Quality />
              </SuspenseWithLoader>
            }
          />

          <Route path=":status" element={<MortgageStatusValidator />}>
            <Route path={quality.documentCategorization}>
              <Route path=":mortgageId" element={<DocumentIdValidator />} />
              <Route
                path=":mortgageId/:documentId"
                element={
                  <SuspenseWithLoader viewportWidth={false}>
                    <DocumentReview />
                  </SuspenseWithLoader>
                }
              />
              <Route
                path=":mortgageId/category/:category"
                element={
                  <SuspenseWithLoader viewportWidth={false}>
                    <Categorized />
                  </SuspenseWithLoader>
                }
              />
            </Route>
          </Route>

          <Route path={quality.closeChecklist}>
            <Route path=":mortgageId">
              <Route
                path="f/:filter/d/:documentId?"
                element={
                  <SuspenseWithLoader viewportWidth={false}>
                    <CloseChecklist />
                  </SuspenseWithLoader>
                }
              />

              <Route index element={<Navigate to={`f/${ChecklistReviewFilter.Review}/d/`} />} />
            </Route>
          </Route>
        </Route>
      )}

      <Route
        path="*"
        element={
          permissionChecker(role, [], [Role.Trader, Role.TradeViewer]) ? (
            <Navigate to={`${mortgage.root}/${mortgage.draft}`} />
          ) : permissionChecker(role, [], [Role.FullDataAction, Role.FullDataViewer]) ? (
            <Navigate to={`${mortgage.root}/${mortgage.close}`} />
          ) : (
            <Navigate to={admin} />
          )
        }
      />
    </SentryRoutes>
  </SuspenseWithLoader>
);
