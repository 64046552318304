import { generatePath, NavLink } from 'react-router-dom';
import { LogoWithName, Settings } from 'shared/images';
import './SidebarNavigation.scss';
import routeStrings, { MortgageRoutes } from '../../../../Routing/routeStrings';
import PermissionGuard from '../../../components/UserFlow/PermissionGuard/PermissionGuard';
import { Role } from '../../../models/User';
import SidebarNavigationQuality from './SidebarNavigationQuality';
import { isQualityAllowed } from '../../../utils/permissionChecker';
import { useProductRole } from '../../../hooks/useProductRole';
import SidebarEntryLogoutOrUserMenu from './SidebarEntryLogoutOrUserMenu';
import { useAppSelector } from 'state-management/hooks';
import { selectSelectedBidTapeId } from 'state-management/slices/bid-tape/bid-tape.selectors';
import { useCallback } from 'react';

const { bidTape, admin } = routeStrings.originator;

const SecondaryMarketingTables = () => {
  const bidTapeId = useAppSelector(selectSelectedBidTapeId);
  const routeWithFilter = useCallback(
    (route: string) => generatePath('/:route/:bidTapeId', { route, bidTapeId }),
    [bidTapeId]
  );

  return (
    <>
      <PermissionGuard needsOneOf={MortgageRoutes.draft.allowed}>
        <NavLink to={routeWithFilter(MortgageRoutes.draft.route)} className="panel-item">
          Pipeline
        </NavLink>
      </PermissionGuard>
      <PermissionGuard needsOneOf={MortgageRoutes.open.allowed}>
        <NavLink to={routeWithFilter(MortgageRoutes.open.route)} className="panel-item">
          Ready for Sale
        </NavLink>
      </PermissionGuard>
      <PermissionGuard needsOneOf={MortgageRoutes.finalize.allowed}>
        <NavLink to={routeWithFilter(MortgageRoutes.finalize.route)} className="panel-item">
          Committed
        </NavLink>
      </PermissionGuard>
      <PermissionGuard needsOneOf={MortgageRoutes.close.allowed}>
        <NavLink to={MortgageRoutes.close.route} className="panel-item">
          Sold
        </NavLink>
      </PermissionGuard>
    </>
  );
};

const SidebarNavigation = () => {
  const { role } = useProductRole();

  return (
    <div className="sidebar-navigation">
      <div className="sidebar-navigation-items">
        <header className="header">
          <LogoWithName className="company-logo" />
        </header>

        <div className="navigation-panels">
          <div>
            <div className="panel-title">Secondary Marketing</div>
            <div className="panel-items">
              <SecondaryMarketingTables />
              <PermissionGuard needsOneOf={[Role.Trader, Role.TradeViewer]}>
                <NavLink to={`/${bidTape}`} className="panel-item">
                  Bid Tape Templates
                </NavLink>
              </PermissionGuard>
            </div>
          </div>

          {isQualityAllowed(role) && (
            <div>
              <div className="panel-title">Quality Control</div>
              <SidebarNavigationQuality />
            </div>
          )}
        </div>
      </div>

      <div className="sidebar-actions">
        <PermissionGuard needsOneOf={[Role.UserManagement]}>
          <NavLink to={`/${admin}`} className="action-item">
            <div className="action-icon">
              <Settings />
            </div>
            User Management
          </NavLink>
        </PermissionGuard>
        <SidebarEntryLogoutOrUserMenu />
      </div>
    </div>
  );
};

export default SidebarNavigation;
